
import { hydrateCSRTemplate } from "@fiverr-private/layout-lib/CSRTemplate";

import Footer from '/home/circleci/app/packages/layout-footer-component/src/entries/Default';

const element = typeof document !== 'undefined' && document.querySelector('#Footer');
if (element) {
    const props = window.initialData['Footer'];

    hydrateCSRTemplate(Footer, props, element, 'Footer', 'Default');
}
export default Footer;
